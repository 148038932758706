<template>
    <div v-html="html">

    </div>
</template>

<script>
    export default {
        name: "corp_e_banking",
        data(){
            return{
                html:""
            }
        },
        mounted() {
            this.html = this.$route.params.formData; // 拿到上个页面传来的数据
            setTimeout(function () {
                document.getElementById("theForm").submit()
            },1000);

        },
    }

</script>

<style scoped>

</style>